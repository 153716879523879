import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../config/firebase'
import { signInWithPopup, TwitterAuthProvider, GoogleAuthProvider,} from "firebase/auth";
import { useAuth } from "../context/AuthContext";
import CupLogo from '../assets/logo-cup.svg';
import BouncingBall from '../components/BouncingBall';



const signInWithTwitter = () => {
  const provider = new TwitterAuthProvider();
  signInWithPopup(auth, provider);
}

const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider);
}

export default function SignIn() {
  const useauth = useAuth();
  const navigate = useNavigate();
  const [userLoading, setUserLoading] = useState<Boolean>(true);
  useEffect(() => {
    const user = useauth?.user;
    const userName = useauth?.userName;
    if (user && userName) {
      navigate(`/user/${userName}`);
    } else {
      setUserLoading(false);
    }
  }, [useauth, navigate]);

  if (userLoading) {
    return <BouncingBall />
  }

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="flex justify-center w-300 border bg-white p-10 rounded">
        <div className="grid grid-cols-1 gap-2">
          <div className="flex justify-center items-center"><img alt="logo" src={CupLogo} /></div>
          <button type="button" onClick={signInWithTwitter} className="min-w-full w-full text-white bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 mr-2 mb-2">
            <svg className="w-4 h-4 mr-2 -ml-1" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M459.4 151.7c.325 4.548 .325 9.097 .325 13.65 0 138.7-105.6 298.6-298.6 298.6-59.45 0-114.7-17.22-161.1-47.11 8.447 .974 16.57 1.299 25.34 1.299 49.06 0 94.21-16.57 130.3-44.83-46.13-.975-84.79-31.19-98.11-72.77 6.498 .974 12.99 1.624 19.82 1.624 9.421 0 18.84-1.3 27.61-3.573-48.08-9.747-84.14-51.98-84.14-102.1v-1.299c13.97 7.797 30.21 12.67 47.43 13.32-28.26-18.84-46.78-51.01-46.78-87.39 0-19.49 5.197-37.36 14.29-52.95 51.65 63.67 129.3 105.3 216.4 109.8-1.624-7.797-2.599-15.92-2.599-24.04 0-57.83 46.78-104.9 104.9-104.9 30.21 0 57.5 12.67 76.67 33.14 23.72-4.548 46.46-13.32 66.6-25.34-7.798 24.37-24.37 44.83-46.13 57.83 21.12-2.273 41.58-8.122 60.43-16.24-14.29 20.79-32.16 39.31-52.63 54.25z"></path></svg>
            Continue with Twitter
          </button>
          <button type="button" onClick={signInWithGoogle} className="min-w-full w-full text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2">
            <svg className="w-4 h-4 mr-2 -ml-1" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512"><path fill="currentColor" d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path></svg>
            Continue with Google
          </button>

        </div>
      </div>
    </div>
  );
}