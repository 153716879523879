import { initializeApp } from "firebase/app";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getAuth, getRedirectResult,connectAuthEmulator, User as FirebaseUser } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";


const config = {
  apiKey:            process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain:        process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL:       process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId:         process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket:     process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId:             process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId:     process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(config);
export const db = getDatabase(app);

export const auth = getAuth();
export type { FirebaseUser };

getRedirectResult(auth)
    .then((result) => {
      if (auth.currentUser) {
        console.log('Logged');
      } else {
        console.log('Not logged');
      }
      
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.email;
      console.log(`AUTH ERROR: email = ${email}, code =  ${errorCode}, message = ${errorMessage}`);
    });

const functions = getFunctions(app);

if (window.location.hostname === "localhost") {
    // Point to the RTDB emulator running on localhost.
    connectDatabaseEmulator(db, "localhost", 9000);
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectFunctionsEmulator(functions, "localhost", 5001);
}

